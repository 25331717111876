$primary: #29373e;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$tertiary: #252525;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Merriweather|Spectral');

html, body {
    height: 100%;
    font-size: 16px;
    @media (max-width: 991px) {
        font-size: 14px;
    }
}

h1,h2,h3,h4 {
    font-family: 'Spectral', serif;
    font-size: 1.75em;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

p {
    font-family: 'Merriweather', serif;
}
.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.navbar-default {
    background: white;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 5px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin-top: 15px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 150px;
    }
}


.top-pad {
    padding: 0.5em;
    background: white;
    
    h1,h2,h3,h4 {
        font-size: 1.4em;
        @media (max-width: 767px) {
            font-size: 1.2em;
        }
    }

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    font-size: 1em;
    font-family: 'Merriweather', serif;
    p {
        font-size: 1em;
        font-family: 'Merriweather', serif;
    }
    
    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.87em;
        }
    }
}

.wrapper {
    position: relative;
    color: white;
    &.solidBg {
        background: $tertiary;
    }

    .sideImg {
        background: url(../img/sideImg1.jpg) no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        height: 100%;
        background-position: 50% 20%;
        @media (max-width: 991px) {
            position: static;
            height: 400px;
        }
    }
    .txtBox {
        height: 100%;
        padding: 13em 15px;
        @media (max-width: 1199px) {
            padding: 10em 15px;
        }
        @media (max-width: 991px) {
            height: auto;
            padding: 8em 15px;
        }
        @media (max-width: 767px) {
            padding: 5em 15px;
        }
        .innerBox {
            vertical-align: middle;
            font-size: 1.2em;
            padding: 0 45px;
            @media (max-width: 991px) {
                padding: 0;
                font-size: 1em;
            }
        }
    }
}

.wrapper2 {
    position: relative;
    .sideImg {
        background: url(../img/sideImg2.jpg) no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        height: 100%;
        background-position: 50% 20%;
        @media (max-width: 991px) {
            position: static;
            height: 400px;
        }
    }
    .txtBox {
        height: 100%;
        padding: 13em 15px;
        @media (max-width: 1199px) {
            padding: 10em 15px;
        }
        @media (max-width: 991px) {
            height: auto;
            padding: 8em 15px;
        }
        @media (max-width: 767px) {
            padding: 5em 15px;
        }
        .innerBox {
            vertical-align: middle;
            font-size: 1.2em;
            padding: 0 45px;
            @media (max-width: 991px) {
                padding: 0;
                font-size: 1em;
            }
        }
    }
}

.padTopBtm {
    padding-top: 50px;
    padding-bottom: 50px;
}

.bg {
    background: $primary;
}

.infoBoxes {
    margin-top: 30px;
    margin-bottom: 30px;

    .infoBox {
        margin-top: 10px;
        margin-bottom: 10px;
        img {
            max-width: 20em;
            width: 100%;
            margin: 0 auto;
        }
    .outline {
        border: 1px solid #eaeaea;
        @media (max-width: 991px) {
            border: none;
        }
        p {
            padding: 25px;
            @media (max-width: 991px) {
                padding: 10px;
                max-width: 30em;
                margin: 0 auto;
            }
        }
      }
    }
}

.banner {
    background: linear-gradient(
        rgba($primary, 0.7), 
        rgba($primary, 0.7)
        ), url('../img/sky.jpg') no-repeat;
    background-blend-mode: multiply;
    background-size: cover;
    padding: 200px 0;
    background-position: 50% 0%;
    color: white;
    @media (max-width: 991px) {
        padding: 100px 0;
    }
    h1 {
        padding: 0 15px;
    }
}

// .container.test {
//     position: relative;
//     // img {
//     //     position: static;
//     //     left: 0;
//     //     height: 100%;
//     // }
//     .imgBox {
//         position: static;
//         left: 0;
//         height: 100%;
//         background: url(../img/img1.jpg) no-repeat;
//         background-size: cover;
//     }
//     .row {
//         [class^="col"] {
//             padding: 500px 0;
//         }
//     }
// }